import { dataConstants } from "redux/constants";
import Axios from "utils/axios";

export const getTransactions = (page, limit, type) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_TRANSACTIONS_REQUEST });      
      let URL = `/student/transactions?page=${page}&limit=${limit}&type=${type}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_TRANSACTIONS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_TRANSACTIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getBorrowings = (page, limit) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_BORROWINGS_REQUEST });      
      let URL = `/student/library-borrowings?page=${page}&limit=${limit}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_BORROWINGS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_BORROWINGS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getCourseDetails = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_COURSE_DETAILS_REQUEST });      
      let URL = `/student/course/${id}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_COURSE_DETAILS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_COURSE_DETAILS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAdmitCards = (page, limit) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ADMIT_CARDS_REQUEST });      
      let URL = `/student/admit-cards?page=${page}&limit=${limit}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_ADMIT_CARDS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_ADMIT_CARDS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getResults = (page, limit) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_RESULTS_REQUEST });      
      let URL = `/student/results?page=${page}&limit=${limit}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_RESULTS_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_RESULTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAdmitCard = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_ADMIT_CARD_REQUEST });      
      let URL = `/student/admit-card/${id}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_ADMIT_CARD_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_ADMIT_CARD_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getResult = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_RESULT_REQUEST });      
      let URL = `/student/result/${id}`;
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: dataConstants.GET_RESULT_SUCCESS,
        payload: data?.data 
      });
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_RESULT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}