import { lazy, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";

import Loadable from "components/Loadable";
import MainLayout from "layout/MainLayout";
import { appConstants } from "redux/constants";

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));

const Courses = Loadable(lazy(() => import("pages/courses")));
const CourseDetail = Loadable(lazy(() => import("pages/courses/course-detail")));

const AdmissionFee = Loadable(lazy(() => import("pages/fee/admission")));
const AdmissionFeeDetail = Loadable(lazy(() => import("pages/fee/admission-fee-detail")));

const ExamFee = Loadable(lazy(() => import("pages/fee/exam")));
const ExamFeeDetail = Loadable(lazy(() => import("pages/fee/exam-fee-detail")));

const Transactions = Loadable(lazy(() => import("pages/transaction")));

const Library = Loadable(lazy(() => import("pages/library")));
const Borrowings = Loadable(lazy(() => import("pages/library/borrowings")));
const Profile = Loadable(lazy(() => import("pages/profile")));
const AdmitCards = Loadable(lazy(() => import("pages/admit-card")));
const Results = Loadable(lazy(() => import("pages/result")));
const AdmitCardDetail = Loadable(lazy(() => import("pages/admit-card/detail")));
const ResultDetail = Loadable(lazy(() => import("pages/result/detail")));

const PrivateRoute = ({ children }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { redirect } = useSelector(state => state.app);
  const { userLoading, isAuthenticated, user } = useSelector(state => state.user);

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
      dispatch({
        type: appConstants.SET_REDIRECT,
        payload: ""
      });
    }
  }, [redirect, navigate, dispatch]);

  return !userLoading && 
    (!isAuthenticated ? 
      <Navigate to={`/auth/login?redirect=${encodeURIComponent(window.location.pathname)}`} />
    : !user?.profileCompleted ? <Navigate to={`/complete-profile`} /> : children); 
};

const MainRoutes = {
  path: "/",
  element: <PrivateRoute><MainLayout/></PrivateRoute>,
  children: [
    {
      path: "",
      element: <Dashboard/>
    },
    {
      path: "courses",
      children: [
        {
          path: "",
          element: <Courses/>
        },
        {
          path: ":id",
          element: <CourseDetail/>
        }
      ]
    },
    {
      path: "admission-fee",
      children: [
        {
          path: "",
          element: <AdmissionFee/>
        },
        {
          path: ":id",
          element: <AdmissionFeeDetail/>
        }
      ]      
    },
    {
      path: "exam-fee",
      children: [
        {
          path: "",
          element: <ExamFee/>
        },
        {
          path: ":id",
          element: <ExamFeeDetail/>
        }        
      ]      
    },
    {
      path: "transactions",
      element: <Transactions/>    
    },
    {
      path: "library",
      children: [
        {
          path: "search-books",
          element: <Library/>
        },
        {
          path: "borrowings",
          element: <Borrowings/>
        }               
      ]      
    },
    {
      path: "profile",
      element: <Profile/>
    },
    {
      path: "admit-cards",
      children: [
        {
          path: "",
          element: <AdmitCards/>
        },
        {
          path: ":id",
          element: <AdmitCardDetail/>
        }               
      ]      
    }, 
    {
      path: "results",
      children: [
        {
          path: "",
          element: <Results/>
        },
        {
          path: ":id",
          element: <ResultDetail/>
        }               
      ]      
    },             
  ]
};

export default MainRoutes;