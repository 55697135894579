import { dataConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  transactionData: null,
  borrowingsData: null,
  admitCardData: null,
  resultData: null,
  admitCard: null,
  result: null,  
};

const dataReducer = (state = initState, action) => {  
  switch (action.type) {
    case dataConstants.GET_TRANSACTIONS_REQUEST:
    case dataConstants.GET_BORROWINGS_REQUEST:
    case dataConstants.GET_COURSE_DETAILS_REQUEST:
    case dataConstants.GET_ADMIT_CARDS_REQUEST:
    case dataConstants.GET_RESULTS_REQUEST:
    case dataConstants.GET_ADMIT_CARD_REQUEST:
    case dataConstants.GET_RESULT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case dataConstants.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,      
        transactionData: action.payload,
        loading: false,
      };  

    case dataConstants.GET_BORROWINGS_SUCCESS:
      return {
        ...state,      
        borrowingsData: action.payload,
        loading: false,
      };

    case dataConstants.GET_COURSE_DETAILS_SUCCESS:
      return {
        ...state,      
        courseDetails: action.payload,
        loading: false,
      };

    case dataConstants.GET_ADMIT_CARDS_SUCCESS:
      return {
        ...state,      
        admitCardData: action.payload,
        loading: false,
      };

    case dataConstants.GET_RESULTS_SUCCESS:
      return {
        ...state,      
        resultData: action.payload,
        loading: false,
      };

    case dataConstants.GET_ADMIT_CARD_SUCCESS:
      return {
        ...state,      
        admitCard: action.payload,
        loading: false,
      };

    case dataConstants.GET_RESULT_SUCCESS:
      return {
        ...state,      
        result: action.payload,
        loading: false,
      };

    case dataConstants.GET_TRANSACTIONS_FAILURE:
    case dataConstants.GET_BORROWINGS_FAILURE:
    case dataConstants.GET_COURSE_DETAILS_FAILURE:
    case dataConstants.GET_ADMIT_CARDS_FAILURE:
    case dataConstants.GET_RESULTS_FAILURE:
    case dataConstants.GET_ADMIT_CARD_FAILURE:
    case dataConstants.GET_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  

    case dataConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case dataConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default dataReducer;