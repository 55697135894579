import { appConstants } from "redux/constants";
import Axios from "utils/axios";

export const getInitialData = () => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_INITIAL_DATA_REQUEST });      
      const { data } = await Axios.get(`/student/initial-data`);            
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_SUCCESS,
        payload: { ...data?.data }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_INITIAL_DATA_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAccessions = (filter, page, limit) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.GET_ACCESSIONS_REQUEST });      
      let URL = `/library/accession?page=${page}&limit=${limit}&libraryId=${filter?.libraryId}`;
      if(filter?.query) URL += `&query=${filter?.query}`;      
      if(filter?.authors) URL += `&authors=${filter?.authors}`;      
      const { data } = await Axios.get(URL);            
      dispatch({ 
        type: appConstants.GET_ACCESSIONS_SUCCESS,
        payload: data?.data
      });
    }catch(error){
      dispatch({ 
        type: appConstants.GET_ACCESSIONS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const updatePassword = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: appConstants.UPDATE_PASSWORD_REQUEST });
      const { data } = await Axios.put(`/auth/student/password`, form);            
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_SUCCESS,
        payload: { 
          message: data?.message,
        }
      });
    }catch(error){
      dispatch({ 
        type: appConstants.UPDATE_PASSWORD_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}