import { formConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  admissionForm: null,
  examForm: null,  
};

const formReducer = (state = initState, action) => {  
  switch (action.type) {               
    case formConstants.GET_FORM_REQUEST:
    case formConstants.SAVE_SUBJECT_SELECTION_REQUEST:
      return {
        ...state,
        loading: true,        
      };

    case formConstants.GET_ADMISSION_FORM_SUCCESS:
      return {
        ...state,
        admissionForm: action.payload,        
        loading: false,
      };

    case formConstants.GET_EXAM_FORM_SUCCESS:
      return {
        ...state,
        examForm: action.payload,        
        loading: false,
      };


    case formConstants.SAVE_SUBJECT_SELECTION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,        
        loading: false,
      };

    case formConstants.GET_FORM_FAILURE:
    case formConstants.SAVE_SUBJECT_SELECTION_FAILURE:
      return {
        ...state,
        error: action.payload,        
        loading: false,
      };

    case formConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case formConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default formReducer;