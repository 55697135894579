import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, List, Typography } from "@mui/material";

import NavItem from "./NavItem";

const NavGroup = ({ item }) => {

  const { admissionFormCount, admitCardCount, borrowCount, examFormCount, resultCount } = useSelector((state) => state.app);
  const menu = useSelector((state) => state.menu);
  
  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {      
      case "item":               
        return <NavItem 
          key={menuItem.id} 
          item={menuItem?.id === "admission-fee" ? {
            ...menuItem,
            badge: admissionFormCount
          } : menuItem?.id === "exam-fee" ? {
            ...menuItem,
            badge: examFormCount
          } : menuItem?.id === "my-borrowings" ? {
            ...menuItem,
            badge: borrowCount
          } : menuItem?.id === "admit-card" ? {
            ...menuItem,
            badge: admitCardCount
          } : menuItem?.id === "results" ? {
            ...menuItem,
            badge: resultCount
          } : menuItem} 
          level={1} 
        />;                
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" color="textSecondary">
              {item.title}
            </Typography>            
          </Box>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {navCollapse}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;