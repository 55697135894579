import { DashboardOutlined } from "@ant-design/icons";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';

const icons = {
  AccountBalanceWalletOutlinedIcon,
  BadgeOutlinedIcon,
  BookOutlinedIcon,
  CheckCircleOutlineOutlinedIcon,
  DashboardOutlined,  
};

const dashboard = {
  id: "dashboard",
  title: "Navigation",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.DashboardOutlined,
      breadcrumbs: true,      
    },
    {
      id: "courses",
      title: "My Courses",
      type: "item",
      url: "/courses",
      icon: icons.BookOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "admit-card",
      title: "Admit Cards",
      type: "item",
      url: "/admit-cards",
      icon: icons.BadgeOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "results",
      title: "My Results",
      type: "item",
      url: "/results",
      icon: icons.CheckCircleOutlineOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "transactions",
      title: "My Transactions",
      type: "item",
      url: "/transactions",
      icon: icons.AccountBalanceWalletOutlinedIcon,
      breadcrumbs: true,      
    }
  ]
};

export default dashboard;