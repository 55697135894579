import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';

const icons = {    
  CreditScoreOutlinedIcon,
  LibraryBooksOutlinedIcon
};

const library = {
  id: "library",
  title: "Library",
  type: "group",
  children: [
    {
      id: "search-books",
      title: "Search Books",
      type: "item",
      url: "/library/search-books",
      icon: icons.LibraryBooksOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "my-borrowings",
      title: "My Borrowings",
      type: "item",
      url: "/library/borrowings",
      icon: icons.CreditScoreOutlinedIcon,
      breadcrumbs: true,       
    },     
  ]
};

export default library;