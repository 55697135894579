import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';

const icons = {    
  CurrencyRupeeOutlinedIcon
};

const fees = {
  id: "fees",
  title: "Fee Payment",
  type: "group",
  children: [
    {
      id: "admission-fee",
      title: "Admission Fee",
      type: "item",
      url: "/admission-fee",
      icon: icons.CurrencyRupeeOutlinedIcon,
      breadcrumbs: true,      
    },
    {
      id: "exam-fee",
      title: "Exam Fee",
      type: "item",
      url: "/exam-fee",
      icon: icons.CurrencyRupeeOutlinedIcon,
      breadcrumbs: true,       
    },     
  ]
};

export default fees;