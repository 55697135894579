import { formConstants } from "redux/constants";
import Axios from "utils/axios";

export const getForm = (id, type) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.GET_FORM_REQUEST });   
      const { data } = await Axios.get(`/student/${type}/fee/${id}`);    
      if(type === "admission"){
        dispatch({ 
          type: formConstants.GET_ADMISSION_FORM_SUCCESS,
          payload: data?.data
        });
      }else if(type === "exam"){
        dispatch({ 
          type: formConstants.GET_EXAM_FORM_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: formConstants.GET_FORM_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const saveSubjectSelection = (form, module) => {
  return async dispatch => {
    try{
      dispatch({ type: formConstants.SAVE_SUBJECT_SELECTION_REQUEST });   
      const { data } = await Axios.post(`/student/${module}/subject-selection`, form);    
      dispatch({ 
        type: formConstants.SAVE_SUBJECT_SELECTION_SUCCESS,
        payload: {
          message: data?.message,
        }
      });      
      dispatch(getForm(form?.formCourseClassId, module));      
    }catch(error){
      dispatch({ 
        type: formConstants.SAVE_SUBJECT_SELECTION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}
