import { combineReducers } from "redux";

import menuReducer from "./menu.reducer";
import userReducer from "./user.reducer";
import appReducer from "./app.reducer";
import dataReducer from "./data.reducer";
import formReducer from "./form.reducer";

const rootReducer = combineReducers({    
  app: appReducer,
  data: dataReducer,
  form: formReducer,
  menu: menuReducer,  
  user: userReducer,
});

export default rootReducer;