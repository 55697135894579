export const menuConstants = {
  ACTIVE_ITEM: "ACTIVE_ITEM",
  ACTIVE_COMPONENT: "ACTIVE_COMPONENT",
  OPEN_DRAWER: "OPEN_DRAWER",
  OPEN_COMPONENT_DRAWER: "OPEN_COMPONENT_DRAWER"
};

export const userConstants = {  
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOAD_USER_REQUEST: "LOAD_USER_REQUEST",
  LOAD_USER_SUCCESS: "LOAD_USER_SUCCESS",
  LOAD_USER_FAILURE: "LOAD_USER_FAILURE",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  COMPLETE_PROFILE_REQUEST: "COMPLETE_PROFILE_REQUEST",
  COMPLETE_PROFILE_SUCCESS: "COMPLETE_PROFILE_SUCCESS",
  COMPLETE_PROFILE_FAILURE: "COMPLETE_PROFILE_FAILURE",
  FORGOT_EMAIL_REQUEST: "FORGOT_EMAIL_REQUEST",
  FORGOT_EMAIL_SUCCESS: "FORGOT_EMAIL_SUCCESS",
  FORGOT_EMAIL_FAILURE: "FORGOT_EMAIL_FAILURE",
  RESET_EMAIL_REQUEST: "RESET_EMAIL_REQUEST",
  RESET_EMAIL_SUCCESS: "RESET_EMAIL_SUCCESS",
  RESET_EMAIL_FAILURE: "RESET_EMAIL_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const appConstants = {
  GET_INITIAL_DATA_REQUEST: "GET_INITIAL_DATA_REQUEST",
  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
  GET_INITIAL_DATA_FAILURE: "GET_INITIAL_DATA_FAILURE",
  GET_ACCESSIONS_REQUEST: "GET_ACCESSIONS_REQUEST",
  GET_ACCESSIONS_SUCCESS: "GET_ACCESSIONS_SUCCESS",      
  GET_ACCESSIONS_FAILURE: "GET_ACCESSIONS_FAILURE",  
  SET_REDIRECT: "SET_REDIRECT",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const dataConstants = { 
  GET_TRANSACTIONS_REQUEST: "GET_TRANSACTIONS_REQUEST",
  GET_TRANSACTIONS_SUCCESS: "GET_TRANSACTIONS_SUCCESS",
  GET_TRANSACTIONS_FAILURE: "GET_TRANSACTIONS_FAILURE",   
  GET_BORROWINGS_REQUEST: "GET_BORROWINGS_REQUEST",
  GET_BORROWINGS_SUCCESS: "GET_BORROWINGS_SUCCESS",
  GET_BORROWINGS_FAILURE: "GET_BORROWINGS_FAILURE",
  GET_COURSE_DETAILS_REQUEST: "GET_COURSE_DETAILS_REQUEST",
  GET_COURSE_DETAILS_SUCCESS: "GET_COURSE_DETAILS_SUCCESS",
  GET_COURSE_DETAILS_FAILURE: "GET_COURSE_DETAILS_FAILURE",
  GET_ADMIT_CARDS_REQUEST: "GET_ADMIT_CARDS_REQUEST",
  GET_ADMIT_CARDS_SUCCESS: "GET_ADMIT_CARDS_SUCCESS",
  GET_ADMIT_CARDS_FAILURE: "GET_ADMIT_CARDS_FAILURE",
  GET_RESULTS_REQUEST: "GET_RESULTS_REQUEST",
  GET_RESULTS_SUCCESS: "GET_RESULTS_SUCCESS",
  GET_RESULTS_FAILURE: "GET_RESULTS_FAILURE",
  GET_ADMIT_CARD_REQUEST: "GET_ADMIT_CARD_REQUEST",
  GET_ADMIT_CARD_SUCCESS: "GET_ADMIT_CARD_SUCCESS",
  GET_ADMIT_CARD_FAILURE: "GET_ADMIT_CARD_FAILURE",
  GET_RESULT_REQUEST: "GET_RESULT_REQUEST",
  GET_RESULT_SUCCESS: "GET_RESULT_SUCCESS",
  GET_RESULT_FAILURE: "GET_RESULT_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}

export const formConstants = {   
  GET_FORM_REQUEST: "GET_FORM_REQUEST",
  GET_ADMISSION_FORM_SUCCESS: "GET_ADMISSION_FORM_SUCCESS",
  GET_EXAM_FORM_SUCCESS: "GET_EXAM_FORM_SUCCESS",
  GET_FORM_FAILURE: "GET_FORM_FAILURE", 
  SAVE_SUBJECT_SELECTION_REQUEST: "SAVE_SUBJECT_SELECTION_REQUEST",
  SAVE_SUBJECT_SELECTION_SUCCESS: "SAVE_SUBJECT_SELECTION_SUCCESS",
  SAVE_SUBJECT_SELECTION_FAILURE: "SAVE_SUBJECT_SELECTION_FAILURE",
  RESET_ERROR_AND_MESSAGE: "RESET_ERROR_AND_MESSAGE",
  RESET: "RESET"
}