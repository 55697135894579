import { appConstants } from "redux/constants";

const initState = {
  loading: false,
  error: null,
  message: null,
  myCourses: [],
  currentCourses: [],
  admissionForms: [],
  admissionFormCount: 0,
  examForms: [],
  examFormCount: 0,
  admissionTransactions: [],
  examTransactions: [],
  libraries: [],
  borrowCount: 0,
  accessionData: null,
  studentCategories: [],
  redirect: "",
  admitCardCount: 0,
  resultCount: 0,
};

const appReducer = (state = initState, action) => {  
  switch (action.type) {    
    case appConstants.GET_INITIAL_DATA_REQUEST:
    case appConstants.GET_ACCESSIONS_REQUEST:    
    case appConstants.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case appConstants.SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload
      }

    case appConstants.GET_INITIAL_DATA_SUCCESS:
      return {
        ...state,      
        myCourses: action.payload?.myCourses,  
        currentCourses: action.payload?.currentCourses,
        admissionForms: action.payload?.admissionForms,
        admissionFormCount: action.payload?.admissionFormCount,
        examForms: action.payload?.examForms,
        examFormCount: action.payload?.examFormCount,
        admissionTransactions: action.payload?.recentAdmissionTransactions,
        examTransactions: action.payload?.recentExamTransactions,
        libraries: action.payload?.libraries,
        borrowCount: action.payload?.libraryBorrowCount,
        studentCategories: action.payload?.studentCategories,
        admitCardCount: action.payload?.admitCardCount,
        resultCount: action.payload?.resultCount,
        loading: false,
      };    

    case appConstants.GET_ACCESSIONS_SUCCESS:
      return {
        ...state,
        accessionData: action.payload,
        loading: false,
      };

    case appConstants.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload?.message,
      };
        
    case appConstants.GET_INITIAL_DATA_FAILURE: 
    case appConstants.GET_ACCESSIONS_FAILURE:   
    case appConstants.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };  

    case appConstants.RESET_ERROR_AND_MESSAGE:
      return{
        ...state,
        error: null,
        message: null        
      } 
      
    case appConstants.RESET:
      return {
        ...initState,        
      }

    default:
      return state;
  }
};

export default appReducer;